import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import ClientUser from "./ClientUser.js";
import ClientInvite from "../Projects/ProjectUsers/ClientInvite.js";
import InviteUser from "./InviteUser.js";
import Select from "react-select";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ClientRoles from "../Organizations/ClientRoles/ClientRoles.js";

const Clients = ({clients, setClients, companies, refetchData, invites, roles}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [openRoles, setOpenRoles] = useState(false);
  
  const clients_list = clients.map(client => {
    return(
      <ClientUser client={client} setClients={setClients} refetchData={refetchData} companies={companies} roles={roles}/>
    )
  });

  const invites_list = invites.filter(invite => invite.is_client == true).map(invite => {

    const handleDelete = () => {
      axios.delete(`/api/o/${match.params.organization_id}/invites/${invite.token}`)
      .then(function(response){
        console.log(response);
        refetchData();
      })
    }

    return(
      <tr className="hover-parent-opacity border-top">
        <td>
          <div className="display-flex">
            <img src={`https://ui-avatars.com/api/?name=${invite.email[0]}&length=1&background=3fade3&color=fff&format=png`} className="people-avatar"/>
            <div>
              <h5 className="font-weight-500 mb-0 mt-0 display-flex align-items-center">
                {invite.email} <span className="badge badge-orange ml-15">Pending</span>
                <CopyToClipboard text={`${window.location.origin}/client_invite/${invite.project_share_token}/${match.params.organization_id}/${invite.token}`} onCopy={() => {setCopied(true); setTimeout(() => setCopied(false), 1000);}}>
                  {copied ? (
                    <span className="badge ml-8 badge-green animated zoomIn"><i className="fal fa-check"></i></span>
                  ):(
                    <a data-tip="Copy invite link" className="badge background-3 background-hover color-1 ml-8"><i className="fal fa-link"></i></a>
                  )}
                </CopyToClipboard>
              </h5>
            </div>
          </div>
        </td>
        <td></td>
        <td></td>
        <td className="max-width-150px">
          {invite.role &&
            <Select
              isDisabled={true}
              value={{value: invite.role?.token, label: invite.role?.title}}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Select..."
            />
          }
        </td>

        <td className="text-right">
          <div class="btn-group">
            <a type="button" class="btn btn-small background-hover color-1 dropdown-toggle opacity-4 opacity-10-hover" data-toggle="dropdown">
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul class="dropdown-menu animated fadeInUp pull-right" role="menu">
              <li><a onClick={handleDelete}><i className="fal fa-trash-alt"></i>Remove invite</a></li>
            </ul>
          </div>
        </td>
      </tr>
    )
  });

  return(
    <React.Fragment>
      {clients.length > 0 ? (
        <div className="queue-table-container">
          <div className="queue-table-header">
            <div className="queue-table-header-left">
              <h4 className="table-header-title">Clients</h4>
              <div className="table-header-description">
                Clients will have access to only the project they've been invited to or created by purchasing a service. <a href="https://guides.usequeue.com/project-collaboration/invite-client-and-team#how-to-invite-a-client-to-a-project" target="_blank">Video guide</a>
              </div>
            </div>

            <div>
              <a className="btn background-active background-hover-2 color-1 mr-15" onClick={() => setOpenRoles(true)}><i class="fal fa-user-shield mr-8"></i>Roles</a>
              <a className="btn btn-primary" onClick={() => setOpen(true)}><i className="fas fa-plus mr-8"></i>Invite client</a>
            </div>
          </div>

          <table class="table queue-table">
            <thead>
              <tr className="queue-table-header-tr">
                <th className="queue-table-th" scope="col">Name</th>
                <th className="queue-table-th" scope="col">Company</th>
                <th className="queue-table-th" scope="col">Last login</th>
                {/* <th className="queue-table-th" scope="col">Project</th> */}
                <th className="queue-table-th" scope="col">Role</th>
                <th className="queue-table-th" scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {clients_list}
              {invites_list}
            </tbody>
          </table>
        </div>
      ):(
        <div className="table-empty-items animated fadeInUp">
          <h4>No clients, yet</h4>
          <p className="opacity-4">This is where you'll see your clients.</p>
        </div>
      )}

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <ClientInvite project_id={match.params.project_id} refetchData={() => {refetchData();}} closeModal={() => setOpen(false)}/> 
      </Modal>

      <Modal open={openRoles} onClose={() => setOpenRoles(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <ClientRoles refetchData={refetchData} roles={roles}/>
      </Modal>
    </React.Fragment>
  )
}

export default Clients;