import React , { useContext, useState } from 'react';
import { useRouteMatch, NavLink } from 'react-router-dom'
import Editor from 'react-simple-wysiwyg';
import axios from "axios";
import FilestackAttachements from "./FilestackAttachements.js";
import { Modal } from 'react-responsive-modal';
import PrivateForm from "./PrivateForm.js";
import Mention from '@tiptap/extension-mention'
import { EditorContent, useEditor, ReactRenderer, BubbleMenu } from '@tiptap/react'
import FileHandler from '@tiptap-pro/extension-file-handler'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import tippy from 'tippy.js'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import Commands from '../../../../Shared/TipTap/commands.js';
import getSuggestionItems from "../../../../Shared/TipTap/items.js";
import MentionList from '../../../../Shared/TipTap/MentionList.jsx'
import renderItems from "../../../../Shared/TipTap/renderItems.js";
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'
import TipTapBubbleMenu from "../../../../Shared/TipTapBubbleMenu.js";
import { useTranslation } from 'react-i18next';
import {TaskboardContext} from "../../../../Shared/TaskboardContext.js";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {OrganizationContext} from "../../../../Shared/OrganizationContext.js";

const DefaultQuestions = ({column, setColumn, refetchData, parent_task_id, closeModal, projectAuthorizations, project_id, usersStrings, columns, setOpenCommunications, setArray}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const {hasPermission} = useContext(OrganizationContext);
  const {setColumns, labels, fullUsers, users} = useContext(TaskboardContext);
  const [disabled, setDisabled] = useState(false);
  const [priority, setPriority] = useState('');
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [attachements, setAttachements] = useState([]);
  const [openFilepicker, setOpenFilepicker] = useState(false)
  const [isPrivate, setIsPrivate] = useState(projectAuthorizations.project_user ? true : false);
  const [advanced, setAdvanced] = useState(false);
  const [selectedTaskLabels, setSelectedTaskLabels] = useState([]);
  const [selectedTaskUsers, setSelectedTaskUsers] = useState([]);
  const [deadline, setDeadline] = useState(null);
  const clientPortal = window.location.pathname.includes("/portal/o/")
  
  // removes the show_task paramater from the url
  const removeNewParam = () => {
    // Get the current URL without query parameters
    const baseUrl = window.location.origin + window.location.pathname;

    // Remove specific query parameter(s)
    const paramsToRemove = ['show_task'];
    const urlWithoutParams = new URL(baseUrl);
    paramsToRemove.forEach(param => urlWithoutParams.searchParams.delete(param));

    // Replace the current URL with the updated URL without parameters
    history.replaceState({}, document.title, urlWithoutParams.toString());
  }

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks`, {
      column_id: column.token,
      title: title, 
      description: description, 
      priority: priority, 
      date: date, 
      parent_task_id: parent_task_id, 
      attachements: attachements, 
      private: isPrivate, 
      selected_task_labels: selectedTaskLabels,
      selected_task_users: selectedTaskUsers, 
      deadline: deadline
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        if(setArray){
          setArray(prevArray => [...prevArray, response.data.task_data]);
        } else {
          setColumns(prevColumns => {
            // console.log("Previous columns:", prevColumns);
            const updatedColumns = prevColumns.map(col => {
              if (col.token === response.data.task_data.column.token) {
                return {
                  ...col,
                  tasks: [...col.tasks, response.data.task_data]
                };
              }
              return col;
            });
            return updatedColumns;
          });
        }

        if(response.data.new_task){
          setOpenCommunications(true)
        } else {
          closeModal();
        }
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const attachements_list = attachements.map((attachement, index) => {
    const handleRemoveAttachment = () => {
      // Create a new array with the attachement removed
      const updatedAttachments = attachements.filter((_, i) => i !== index);
      setAttachements(updatedAttachments); // Update the state
    };

    return(
      <div className="filepicker-attachement-item">
        <i onClick={handleRemoveAttachment} className="fas fa-times task-attachement-remove"></i>
        <a target="_blank" className="color-blue" href={attachement.url}>{attachement.filename}</a>
      </div>
    )
  });

  // Editor
  const editor = useEditor({
    onUpdate({ editor }) {
      setDescription(editor.getHTML())
    },
    extensions: [
      StarterKit,
      Image,
      TaskList,
      Underline,
      Highlight.configure({ multicolor: true }),
      TaskItem.configure({
        nested: true,
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      FileHandler.configure({
        allowedMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp'],
        onDrop: (currentEditor, files, pos) => {
          files.forEach(file => {
            const fileReader = new FileReader()

            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
              currentEditor.chain().insertContentAt(pos, {
                type: 'image',
                attrs: {
                  src: fileReader.result,
                },
              }).focus().run()
              
            }
          })
        },
        onPaste: (currentEditor, files, htmlContent) => {
          files.forEach(file => {
            if (htmlContent) {
              return false
            }

            const fileReader = new FileReader()

            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
              notice("Pasting image...")
              axios.post(`/api/upload_to_digitalocean`, {
                base64: fileReader.result
              })
              .then(function(response){
                if(response.data.success){
                  currentEditor.chain().insertContentAt(currentEditor.state.selection.anchor, {
                    type: 'image',
                    attrs: {
                      src: response.data.image_link,
                      class: 'your-custom-class',
                    },
                  }).focus().run()
                } else {
                  response.data.errors.forEach((error) => {
                    notice(error);
                  });
                }
              })
              .catch(function(error){
                console.log(error)
                notice("An error occured");
                reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
              })
              .then(function () {

              });
            }
          })
        },
      }),
      Placeholder.configure({
        placeholder: '/slash command...',
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention border-all background-3',
        },
        suggestion: {
          items: ({ query }) => {
            return usersStrings
              .filter((item) =>
                item.toLowerCase().startsWith(query.toLowerCase())
              )
              .slice(0, 5);
          },
          render: () => {
            let reactRenderer;
            let popup;

            return {
              onStart: (props) => {
                reactRenderer = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor
                });

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start"
                });
              },
              onUpdate(props) {
                reactRenderer.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect
                });
              },
              onKeyDown(props) {
                if (props.event.key === "Escape") {
                  popup[0].hide();

                  return true;
                }

                return reactRenderer?.ref?.onKeyDown(props);
              },
              onExit() {
                popup[0].destroy();
                reactRenderer.destroy();
              }
            };
          }
        }
      }),
      Commands.configure({
        suggestion: {
          items: getSuggestionItems,
          render: renderItems
        }
      })
    ],
    content: description,
  });

  const labelOptions = labels?.map((label) => ({
    value: label.token,
    label: label.title
  }));  

  const userOptions = (fullUsers || users)?.map((user) => ({
    value: user.id,
    label: user.username
  }));

  return(
    <React.Fragment>
        <h4 className="color-black-always modal-title">{t("New task")}</h4>

        <div className="row no-margin">
          <form onSubmit={handleSubmit}>
            <div className="field">
              <label>{t("Title")}</label>
              <input value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" required="true" autoFocus/>
            </div>

            <div className="field">
              <label>{t("Description")}</label>
              {/* <Editor value={description} onChange={(e) => setDescription(e.target.value)} containerProps={{ style: { minHeight: 150 } }}/> */}
              {editor && 
                <TipTapBubbleMenu editor={editor} />
              }
              <EditorContent editor={editor} className="border-all border-radius"/>
            </div>

            <div className="field row">
              <div className="col-12">
                <label htmlFor="priority">{t("Priority")}</label>
                <select className="form-control" id="priority" value={priority} onChange={handlePriorityChange}>
                  <option value="">Select priority</option>
                  <option value="High">High</option>
                  <option value="Medium">Medium</option>
                  <option value="Low">Low</option>
                </select>
              </div>
            </div>
            
            {!clientPortal && 
              <>
                {(projectAuthorizations.organization_user && !parent_task_id) ?(
                  <div className="field display-flex align-items-center">
                    <NavLink className="btn btn-small opacity-6 opacity-10-hover color-1 mr-5 border-right" to={`/o/${match.params.organization_id}/task_forms`}><i className="fas fa-plus mr-8"></i>Add custom questions</NavLink>

                    <a className={`btn btn-small opacity-6 opacity-10-hover color-1 ${advanced ? "background-active" : "background-hover"}`} onClick={() => setAdvanced(!advanced)}>
                      <i className={`fas ${advanced ? "fa-cogs" : "fa-cog"} mr-5`}></i>
                      Admin options
                    </a>
                  </div>
                ) : (
                  <a className={`btn btn-small opacity-6 opacity-10-hover color-1 ${advanced ? "background-active" : "background-hover"}`} onClick={() => setAdvanced(!advanced)}>
                    <i className={`fas ${advanced ? "fa-cogs" : "fa-cog"} mr-5`}></i>
                    Admin options
                  </a>
                )}
              </>
            }

            <div className="">
              {/* <a onClick={() => setOpenFilepicker(true)} className="color-1 dark-color-black"><i className="fas fa-link mr-5"></i>Upload files</a> */}
              <Modal open={openFilepicker} onClose={() => setOpenFilepicker(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                <FilestackAttachements setAttachements={setAttachements} closeModal={() => setOpenFilepicker(false)}/>
              </Modal>
              <div className="field filepicker-attachement-images">
                {attachements_list}
              </div>
            </div>

            <div className="field" style={{marginBottom: 20}}>
              {advanced && ( 
                <div className="row">
                  <div className="field">
                    <label>Labels</label>
                    <Select
                      isMulti={true}
                      options={labelOptions}
                      value={selectedTaskLabels}
                      onChange={(selectedOptions) => setSelectedTaskLabels(selectedOptions)}
                      isDisabled={disabled}
                      className="color-1"
                      classNamePrefix="react-select"
                      placeholder="Select labels..."
                    />
                  </div>
                
                  <div className="field">
                    <label>Assign members</label>
                    <Select
                      isMulti={true}
                      options={userOptions}
                      value={selectedTaskUsers}
                      onChange={(selectedOptions) => setSelectedTaskUsers(selectedOptions)}
                      isDisabled={disabled}
                      className="color-1"
                      classNamePrefix="react-select"
                      placeholder="Select members..."
                    />
                  </div>

                  <div className="field">
                    <label>Due date</label>
                    <div>
                      <DatePicker selected={deadline} onChange={(date) => setDeadline(date)} className="task-question-answer-input background-hover form-control height-38px"  placeholderText="Set Due Date"/>
                    </div>
                  </div>
                </div> 
              )}
            </div>

            <div className="field text-right mt-15">
              <a onClick={() => setOpenFilepicker(true)} className="color-1 dark-color-black"><i className="fas fa-paperclip mr-15"></i></a>
              
              {(projectAuthorizations.organization_user || projectAuthorizations.project_user) && 
                <PrivateForm isPrivate={isPrivate} setIsPrivate={setIsPrivate} />
              }

              {setColumn != null && 
                <div class="btn-group dropup">
                  <button type="button" class="btn btn-small btn-primary-outline dropdown-toggle mr-15" data-toggle="dropdown">
                    {column.title}<i className="fas fa-angle-up ml-8"></i>
                  </button>

                  <ul class="dropdown-menu dropup animated fadeInUp" role="menu">
                    {columns.filter(columnItem => hasPermission(columnItem.stage)).map(columnItem => (
                      <li className={((columnItem.tasks.length < columnItem.limit) || columnItem.limit === null)? "" : "disabled"} key={columnItem.token} onClick={() => setColumn(columnItem)}><a>{columnItem.title}</a></li>
                    ))}
                  </ul>
                </div>
              }
              
              <button disabled={disabled} className="btn btn-primary" type="submit">{t("Submit")}</button>
            </div>
          </form>
        </div>
    </React.Fragment>
  )
}

export default DefaultQuestions