import React from 'react';
import { useTranslation } from 'react-i18next';

const PrivateForm = ({isPrivate, setIsPrivate}) => {
  const { t } = useTranslation();

  return(
    <React.Fragment>
      <div class="btn-group dropup">
          {isPrivate ? (
            <React.Fragment>
              <a type="button" class="btn private-btn btn-small dropdown-toggle mr-15" data-toggle="dropdown">
                <i class="fal fa-lock mr-5"></i>{t("Team only")}<i class="far fa-angle-up ml-10"></i>
              </a>
            </React.Fragment>
          ):(
            <React.Fragment>
              <a type="button" class="btn btn-primary-outline btn-small dropdown-toggle mr-15" data-toggle="dropdown">
                <i class="far fa-globe-americas mr-5"></i>{t("Everyone")}<i class="far fa-angle-up ml-10"></i>
              </a>
            </React.Fragment>
          )}
        <ul class="dropdown-menu animated fadeInUp" role="menu">
          <li><a onClick={() => setIsPrivate(false)}><i class="far fa-globe-americas mr-5"></i>{t("Everyone")}</a></li>
          <li><a onClick={() => setIsPrivate(true)}><i class="fal fa-lock mr-5"></i>{t("Team only")}</a></li>
        </ul>
      </div>
    </React.Fragment>
  )
}

export default PrivateForm;