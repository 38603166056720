import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {
  Menu,
  Item,
  useContextMenu,
  Submenu, 
  Separator
} from "react-contexify";
import {TaskboardContext} from "../Shared/TaskboardContext.js";
import { confirmAlert } from 'react-confirm-alert'; 

const TaskLabelPicked = ({task_label, task, refetchData, setArray, subtask}) => {
  const {setLabels, columns, setColumns} = useContext(TaskboardContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);

  // delete label
  const removeLabel = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Stop event propagation

    confirmAlert({
      title: 'Are you sure you want to delete this label?',
      message: (
        <div style={{ opacity: 0.6 }}>
          This will delete this label and remove it from all tasks that it was assigned to. 
          <br /> <br />
          <div style={{ opacity: 0.6 }}>
            <i className="fal fa-info-circle mr-5"></i> If you want to remove the label only from this task, click cancel and then click on the labels name.
          </div>
        </div>
      ),
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: () => {
            axios.delete(`/api/o/${match.params.organization_id}/labels/${task_label.label.token}`)
            .then(function(response){
              // console.log(response);
              if(response.data.success){
                setLabels(prevState => _.reject(prevState, { token: task_label.label.token }));
                // refetchData();
              } else {
                response.data.errors.forEach((error) => {
                  notice(error);
                });
              }
            })
            .catch(function(error){
              // console.log(error)
              reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
              refetchData();
            })
            .then(function () {
              setDisabled(false);
            });
          }
        },
      ]
    });
  }

  // remove task label
  const removeTaskLabel = () => {
    axios.delete(`/api/portal/projects/${match.params.project_id}/tasks/${task.token}/task_labels/${task_label.token}`)
    .then(function(response){
      if(response.data.success){
        if(subtask){
          setArray(prevArray => {
            return prevArray.map(item => 
              item.token === response.data.task_data.token ? response.data.task_data : item
            );
          });
        } else {
          setColumns(prevColumns => {
            return prevColumns.map(col => {
              if (col.title === task.column.title) {
                return {
                  ...col,
                  tasks: col.tasks.map(t => 
                    t.token === task.token ? response.data.task_data : t
                  )
                };
              }
              return col;
            });
          });
        }

        // refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      refetchData();
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <Item closeOnClick={false} onClick={removeTaskLabel}>
        <div className="display-flex align-items-center justify-content-between label-item-hover-parent" style={{width: "100%"}}>
          <div className="display-flex align-items-center">
            <span className="label-color" style={{background: `#${task_label.label.color}`}}></span>
            {task_label.label.title}
            <i className="fas fa-check ml-15"></i>
          </div>

          <i className="fal fa-trash ml-15 label-item-hover-child color-red-hover animate" onClick={removeLabel}></i>
        </div>
      </Item>
    </React.Fragment>
  )
}

export default TaskLabelPicked;