import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import ReplayDropdown from "../../../../../Replays/ReplayDropdown.js";

const Index = ({project_id, task_id}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [taskReplays, setTaskReplays] = useState([]);

  useEffect(() => {
    fetchTaskReplays();
  }, []);

  const fetchTaskReplays = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${project_id}/tasks/${task_id}/task_replays.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setTaskReplays(response.data.replays);
      } else {
        notice(response.data.errors);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const taskReplaysList = taskReplays?.map((taskReplay) => {
    return (
      <div key={taskReplay.token} className="display-flex width-100-percent align-items-center justify-content-between truncate-parent border-bottom default-padding hover-parent-opacity position-relative pl-0">
        <a href={`/replays/${taskReplay.token}`} target="_blank" className="truncate-child pl-5">
          <img src={taskReplay.preview_image} className="replay-avatar mr-8" alt={taskReplay.title}/>
          {taskReplay.title}
        </a>
        <div className="font-12 opacity-5 pl-15 pr-15">
          {taskReplay.created_at}
        </div>

        <div className="font-12 opacity-5 pl-15 pr-15">
          <img src={taskReplay.user.avatar} className="avatar-small mr-8" alt={taskReplay.user.avatar}/>
          {taskReplay.user.username}
        </div>
      </div>
    )
  })
  
  return(
    <React.Fragment>
      {taskReplaysList}
    </React.Fragment>
  )
}

export default Index;