import React , { useState, useContext } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import { Draggable } from "react-beautiful-dnd";
import { Modal } from 'react-responsive-modal';
import FullCardV2 from "../FullCardV2.js";
import TaskDropdown from "../TaskForms/TaskDropdown.js";
import ReactTooltip from 'react-tooltip';
import { AppContext } from "../../../../Shared/AppContext.js";
import axios from "axios"; 
import {Menu, Item, useContextMenu} from "react-contexify";
import StageIcon from "../../../../Shared/StageIcon.js";
import UpdateColumn from "./List/UpdateColumn.js";
import {TaskboardContext} from "../../../../Shared/TaskboardContext.js";
import Cover from "./Cover.js";
import NewLabel from "../../../../Labels/NewLabel.js";
import NewTaskTemplate from "../../../../TaskTemplates/NewTaskTemplate.js";

const List = ({card, index, refetchData, draggable_id, openTask, setOpenTask, project_id, column, setArray, isSubtask}) => {
  const {markNotificationRead} = useContext(AppContext);
  const {columns} = useContext(TaskboardContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [showSubtasks, setShowSubtasks] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [openTaskTemplate, setOpenTaskTemplate] = useState(false);
  const [openCover, setOpenCover] = useState(false);
  const [subtasks, setSubtasks] = useState(card?.subtasks || []);

  const users_list = card?.users?.map(user => {
    return(
      <img src={user.avatar} className="kanban-card-users-image"/>
    )
  }); 

  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `${card.token}-task-dropdown` }).show({ event: e });
  };

  // const subtasks_list = card.subtasks.sort((a, b) => a.position - b.position).map((subtask, index) => (
  //   <List key={subtask.token} card={subtask} index={index} refetchData={refetchData} organizationUser={organizationUser} column={column} columns={columns} draggable_id={subtask.parent_task_id} projectAuthorizations={projectAuthorizations} project_id={project_id} users={users} project_title={project_title} setOpenTask={setOpenTask}/>
  // ));

  const markFinished = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${card.token}`, {
      finished: !card.finished
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const { show } = useContextMenu({
    id: card.token
  });
  function displayMenu(e){
    e.stopPropagation();
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }

  const task_labels_list = card?.task_labels?.map(task_label => {
    return(
      <span className={`badge border-all mr-10`}>
        <span className="badge-label-color" style={{background: `#${task_label.label.color}`}}></span>
        {task_label.label.title}
      </span>
    )
  });

  const columns_list = columns.filter(column => column.title !== card.column.title).map(column => {
    return(
      <UpdateColumn column={column} project_id={project_id} task_id={card.token}/>
    )
  });

  const subtasks_list = subtasks?.sort((a, b) => a.position - b.position).map((subtask, index) => (
    <List key={subtask.token} card={subtask} refetchData={refetchData} column={column} project_id={project_id} setOpenTask={setOpenTask} setArray={setSubtasks} isSubtask={true}/>
  ));


  return(
    card != null && 
    <React.Fragment>
      <Draggable key={String(card.id)} draggableId={String(draggable_id)} index={index}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              <div onContextMenu={displayMenu} className="cursor-pointer background-hover taskboard-list-wrapper border-bottom" onClick={() => {setOpen(true); history.push(`?task_id=${card.token}`);}}>
                <div className="display-flex align-items-center pl-15 font-13">
                  <a data-tip="Change stage" className="color-1 btn opacity-6 opacity-10-hover btn-small ml-15-negative mr-8" onClick={handleContextMenu}>
                    <StageIcon stage={card.column.stage} classNames="no-class-here"/>
                  </a>

                  <Menu id={`${card.token}-task-dropdown`}>
                    {columns_list}
                  </Menu>

                  {card.private && 
                    <i className="fal fa-lock mr-5"></i>
                  }
                  {/* Priority */}
                  {card.priority ? ( 
                    <>
                      <div data-tip={card.priority} className={`mr-8 mb-0 kanban-list-info-status mr-8 ${card.priority == "High" && "background-red"} ${card.priority == "Medium" && "background-orange"} ${card.priority == "Low" && "background-blue"}`}></div>
                    </>  
                  ):(
                    <div data-tip="No priority" className="mr-8 mb-0 kanban-list-info-status mr-8 background-border"></div>
                  )}
                  {card.title}
                </div>

                {/* Subtasks */}
                <div className="hover-parent-opacity display-flex align-items-center flex-direction-row card-list-view-info-wrapper">
                  <div className="card-list-view-info-wrapper-inner-subtasks">
                    {(card.subtasks && card.subtasks.length > 0) &&
                      <>
                        <span onClick={(e) => {e.stopPropagation(); setShowSubtasks(!showSubtasks)}} data-tip="Subtasks" className={`badge ${card.subtasks.every(subtask => subtask.finished) ? "badge-green" : "background-active"} hover-scale cursor-default mr-5 font-weight-500 task-deadline-wrapper`}>
                          {card.subtasks.every(subtask => subtask.finished) ? (
                            <>
                              <i class="far fa-check-square mr-5"></i>
                            </>
                          ):(
                            <>
                              <i class="far fa-square mr-5"></i>
                            </>
                          )}
                          {card.subtasks.filter(subtask => subtask.finished).length}/{card.subtasks.length}
                        </span>
                      </>
                    }
                  </div>
                  
                  {/* Deadline */}
                  <div className="card-list-view-info-wrapper-inner-deadline">

                    {card.deadline != null &&
                      <span onClick={markFinished} data-tip="Due date" className={`badge ${card.finished ? "badge-green" : card.deadline_color} cursor-default mr-5  font-weight-500 task-deadline-wrapper`}>
                        {disabled ? (
                          <>
                            <i className="fas fa-spinner fa-spin mr-5"></i>
                          </>
                        ):(
                          <>
                            {card.finished ? (
                              <>
                                <i class="far fa-check-square mr-5 task-deadline-clock"></i>
                                <i class="far fa-square mr-5 task-deadline-check" data-tip="Mark unfinished"></i>
                              </>
                            ):(
                              <>
                                <i class="far fa-clock mr-5 task-deadline-clock"></i>
                                <i class="far fa-square mr-5 task-deadline-check" data-tip="Mark finished"></i>
                              </>
                            )}
                          </>
                        )}
                        {card.deadline_short}
                      </span>
                    }
                  </div>
                  
                  <div className="card-list-view-info-wrapper-inner-labels">
                    {task_labels_list}
                  </div>

                  {/* Project link for dashboard */}
                  <div className="card-list-view-info-wrapper-inner-project">
                    {card.project != null && 
                      <Link to={`/o/${match.params.organization_id}/projects/${card.project.token}/tasks`} className="badge background-3 background-hover opacity-6 opacity-10-hover color-1" onClick={(e) => e.stopPropagation()}>
                        <img src={card.project.avatar} className="project-list-avatar" style={{width: 15, height: 15}}/>
                        {card.project.title}
                      </Link>
                    }
                  </div>

                  {/* Users */}
                  <div className="card-list-view-info-wrapper-inner-users ml-8 font-12">
                    {users_list}
                  </div>

                  <span className="badge opacity-4 ml-8 card-list-view-info-wrapper-inner-created-at" data-tip="Created on">
                    {card.created_at}
                  </span>

                  {/* <TaskDropdown show={show} task={card} refetchData={refetchData} columns={columns} project_id={project_id} organizationUser={organizationUser} displayMenu={displayMenu}/> */}
                  <TaskDropdown task={card} refetchData={refetchData} column={column} project_id={project_id} displayMenu={displayMenu} setOpenNewLabel={setOpenNewLabel} setOpenTaskTemplate={setOpenTaskTemplate} setOpenCover={setOpenCover} hideIcon={false} setArray={setArray} subtask={isSubtask} />
                </div>
              </div>
              
              {/* Subtasks */}
              {showSubtasks && 
                <div className="animated fadeIn" style={{marginLeft: "20px", paddingLeft: "5px", borderLeft: "1px solid #c5c7ca"}}>
                  {subtasks_list}
                </div>
              }
          </div>
        )}
      
      
        
      </Draggable>
      <Modal focusTrapped={false} closeOnEsc={false} open={open || openTask} onClose={() => {setOpen(false); history.replace(location.pathname); setOpenTask(false)}} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
        <FullCardV2 task_id={card.token} refetchData={refetchData} project_id={project_id}/>
      </Modal>

      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => setOpenNewLabel(false)}/>
      </Modal>
      
      <Modal open={openTaskTemplate} onClose={() => setOpenTaskTemplate(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewTaskTemplate task={card} refetchData={refetchData} project_id={project_id} closeModal={() => setOpenTaskTemplate(false)}/>
      </Modal>

      <Modal open={openCover} onClose={() => setOpenCover(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <Cover task_id={card.token} refetchData={() => {refetchData();}} closeModal={() => setOpenCover(false)} cover_exists={card.cover_url != null || card.unsplash_data != null}/>
      </Modal>
    </React.Fragment>
  )
}

export default List;