import React , { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import {UserContext} from "../../../Shared/UserContext.js";
import { Modal } from 'react-responsive-modal';
import axios from "axios"; 
import Linkify from 'react-linkify';
import localForage from "localforage";
import Upload from "./Upload.js";
import Form from "./TaskComments/Form.js";
import TaskUsers from "./TaskForms/TaskUsers.js";
import DefaultQuestions from "./TaskForms/DefaultQuestions.js";
import TaskComment from "./TaskComments/TaskComment.js";
import Pusher from 'pusher-js';
import DisplayHTML from "../../../Shared/DisplayHTML.js";
import DOMPurify from 'dompurify';
import Editor from 'react-simple-wysiwyg';
import TaskReplay from "./TaskReplay.js";
import Skeleton from "react-loading-skeleton"
import SubTask from "./SubTask.js";
import { AppContext } from "../../../Shared/AppContext.js";
import Files from "./Task/Files.js";
import Field from "./Task/Field.js";
import TipTap from "./Task/TipTap.js";
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Menu,Item, useContextMenu, Separator} from "react-contexify";
import _ from 'lodash';
import TaskLabels from "../../../Labels/TaskLabels.js";
import {TaskboardContext} from "../../../Shared/TaskboardContext.js";
import DatePicker from "react-datepicker";
import TaskOwner from "./Task/TaskOwner.js";
import StageIcon from "../../../Shared/StageIcon.js";
import Cover from "./Task/Cover.js";
import { useHotkeys } from 'react-hotkeys-hook'
import TaskLabelPicked from "../../../Labels/TaskLabelPicked.js";
import ReactTooltip from 'react-tooltip';
import AiSubtasks from "./TaskForms/AiSubtasks.js";
import {OrganizationContext} from "../../../Shared/OrganizationContext.js";
import NewProperty from "./Task/NewProperty.js";
import UploadAttachements from "./Task/UploadAttachements.js";
import AddTaskTemplate from "../../../TaskTemplates/AddTaskTemplate.js";
import { VscGripper } from "react-icons/vsc";
import "./style.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import TaskTimeTracker from "../../../TimeTracker/TaskTimeTracker.js";
import {ProjectContext} from "../../../Projects/ProjectContext.js";
import { MdOutlineMarkUnreadChatAlt } from "react-icons/md";
import { Portal } from 'react-portal';
import TaskReplaysIndex from "./Task/TaskReplays/Index.js";

const FullCardV2 = ({refetchData, project_id, task_id}) => {
  const {hasPermission} = useContext(OrganizationContext)
  const {labels, organizationUser, projectAuthorizations, setOpenNewLabel} = useContext(TaskboardContext);
  const history = useHistory();
  const {testers} = useContext(ProjectContext);
  const currentUser = useContext(UserContext);
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [task, setTask] = useState();
  const [description, setDescription] = useState("");
  const [editDescription, setEditDescription] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [taskComments, setTaskComments] = useState([]);
  const [replays, setReplays] = useState([]);
  const [openDefaultQuestions, setOpenDefaultQuestions] = useState(false)
  const [taskAttachements, setTaskAttachements] = useState([]);
  const [limit, setLimit] = useState(10);
  const [loadingComments, setLoadingComments] = useState(false);
  const [taskCommentsSize, setTaskCommentsSize] = useState(null);
  const [taskCommentsLoaded, setTaskCommentsLoaded] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [subtasks, setSubtasks] = useState([]);
  const [taskLabels, setTaskLabels] = useState([]);
  const [settingVersions, setSettingVersions] = useState(false);
  const [showSubtasks, setShowSubtasks] = useState(true);
  const [usersStrings, setUsersStrings] = useState([]);
  const [openCover, setOpenCover] = useState(false);
  const [openParentTask, setOpenParentTask] = useState(false);
  const [selectedSubtaskIndex, setSelectedSubtaskIndex] = useState(-1);
  const [openSubtaskToken, setOpenSubtaskToken] = useState(null);
  const clientPortal = window.location.pathname.includes("/portal/o/")
  const [columns, setColumns] = useState([]);
  const [loadingHanged, setLoadingHanged] = useState(false);
  const [addSubtask, setAddSubtask] = useState(false);
  const [quickSubtaskColumn, setQuickSubtaskColumn] = useState(null);
  const [quickSubtaskInput, setQuickSubtaskInput] = useState("");

  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `task-${task.token}-subtasks-dropdown` }).show({ event: e });
  };

  useEffect(() => {
    axios.get(`/api/portal/projects/${project_id || match.params.project_id}/columns.json`)
    .then(function(response){
      // console.log(response);
      setColumns(response.data.columns)
    })
  }, [project_id, match.params.project_id])

  // Add this useEffect hook to scroll the selected subtask into view
  useEffect(() => {
    if (selectedSubtaskIndex >= 0 && selectedSubtaskIndex < subtasks.length) {
      const selectedElement = document.getElementById(`subtask-${subtasks[selectedSubtaskIndex].token}`);
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [selectedSubtaskIndex]);

  // Add these new hotkey handlers
  // Add this new hotkey handler for the Escape key
  useHotkeys('esc', () => {
    setSelectedSubtaskIndex(-1);
  }, []);

  useHotkeys('enter', () => {
    if (selectedSubtaskIndex >= 0 && selectedSubtaskIndex < subtasks.length) {
      setOpenSubtaskToken(subtasks[selectedSubtaskIndex].token);
    }
  }, [selectedSubtaskIndex, subtasks]);

  useHotkeys('j', () => {
    setSelectedSubtaskIndex((prevIndex) => 
      prevIndex < subtasks.length - 1 ? prevIndex + 1 : prevIndex
    );
  }, [subtasks]);

  useHotkeys('k', () => {
    setSelectedSubtaskIndex((prevIndex) => 
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  }, [subtasks]);
  
  useHotkeys('s', (event) => {
    event.preventDefault();
    setAddSubtask(true);
  }, []);
  
  useHotkeys('l', (event) => {
    event.preventDefault();
    displayMenu(event);
  }, [displayMenu]);

  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const timeoutId = setTimeout(() => {
        setLoadingHanged(true);
      }, 5000);

      try {
        const value = await localForage.getItem(`task-show-${task_id}-v2`);
        if(value){
          setTask(value.task);
          setReplays(value.task.replays);
          setTaskAttachements(value.task.task_attachements);
          setSubtasks(value.subtasks || []);
          setUsersStrings(value.task.user_strings);
          setTaskLabels(value.task_labels || []);
          setLoaded(true);
        }
        await fetchTask(signal);
        clearTimeout(timeoutId);
      } catch (error) {
        console.error('Error fetching task data:', error);
        setLoadingHanged(true);
      }
    }

    fetchData();
    
    return () => {
      controller.abort();
    }
  }, [task_id, project_id])

  // Update LocalForage when subtasks state changes
  useEffect(() => {
    localForage.getItem(`task-show-${task_id}-v2`)
      .then(function (value) {
        if (value) {
          value.task = task;
          value.subtasks = subtasks;
          value.task_labels = taskLabels;
          localForage.setItem(`task-show-${task_id}-v2`, value)
            .then(function (updatedValue) {
              // console.log('Subtasks updated in LocalForage:', updatedValue.subtasks);
            })
            .catch(function(err) {
              console.error('Error updating subtasks in LocalForage:', err);
            });
        }
      })
      .catch(function(err) {
        console.error('Error loading data from LocalForage:', err);
      });
  }, [task, subtasks, taskLabels]);

  useEffect(() => {
    // setLoadingComments(true)
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const value = await localForage.getItem(`task-comments-${task_id}`);
      if(value){
        setTaskComments(value.task_comments);
        setTaskCommentsSize(value.task_comments_size);
        setTaskCommentsLoaded(true)
        if(document.getElementById("full-card-comments-wrapper")){
          document.getElementById("full-card-comments-wrapper").scrollTop = document.getElementById("full-card-comments-wrapper").scrollHeight;
        }
      }
      await fetchTaskComments(signal);
    }

    fetchData();

    return () => {
      controller.abort();
    }
  }, [limit, task_id, project_id])

  useEffect(() => {
    const pusher = new Pusher('683f89863cff332fae20', {cluster: 'us2'});
    const channel = pusher.subscribe(`project-${project_id || match.params.project_id}-tasks-${task_id}-task_comments`);

    channel.bind('subscription', data => {
      // Handle the incoming event data here
      // console.log('Received data:', data);
      if(data.user_id != currentUser.id){
        fetchTask();
        fetchTaskComments();
        refetchData();
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const fetchTask = async (signal) => {
    try {
      const response = await axios.get(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}.json`, {signal})

      if(response.data.success){
        setTask(response.data.task);
        setReplays(response.data.task.replays);
        setTaskAttachements(response.data.task.task_attachements);
        setSubtasks(response.data.task.subtasks)
        setUsersStrings(response.data.task.user_strings);
        setTaskLabels(response.data.task.task_labels);
        setLoaded(true);

        await localForage.setItem(`task-show-${task_id}-v2`, response.data);

        // Initialize jQuery drag and drop
        $(".task-draggable").draggable({
          revert: "invalid",
          zIndex: 100,
          stack: ".task-draggable",
          start: function(event, ui) {
            $(this).addClass("dragging"); // Add a class when dragging starts
            $(this).addClass("background-active"); // Add class when draggable is dragged over
          },
          stop: function(event, ui) {
            $(this).removeClass("dragging"); // Remove the class when dragging stops
            $(this).removeClass("background-active"); // Remove class when draggable is dragged out
          }
        });

        $(".task-droppable").droppable({
          accept: ".task-draggable",
          drop: function(event, ui) {
            const draggedReplay = ui.draggable;
            const droppedReplay = $(this);
            createVersion(draggedReplay.data("replay_id"), droppedReplay.data("replay_id"));
          },
          over: function(event, ui) {
            
          },
          out: function(event, ui) {
            
          }
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
    }
  }

  const createVersion = (dragged_replay_id, dropped_replay_id) => {
    // Filter out the replay with the specified id
    setSettingVersions(true)
    const updatedReplays = replays.filter(replay => replay.token !== dragged_replay_id);
    // Update state with the filtered replays
    setReplays(updatedReplays);

    axios.post(`/api/replays/${dropped_replay_id}/create_ancestory`, {
      dragged_replay_id: dragged_replay_id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchTask();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
      setSettingVersions(false);
    });
  }

  const fetchTaskComments = async (signal) => {
    try {
      const response = await axios.get(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}/task_comments.json?limit=${limit}`, {signal})
      if(response.data.success){
        setTaskComments(response.data.task_comments);
        setTaskCommentsSize(response.data.task_comments_size);
        setTaskCommentsLoaded(true); 
        await localForage.setItem(`task-comments-${task_id}`, response.data)
      } else {
        notice(response.data.error);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
    }
  };

  const updateDescription = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      description: description
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchTask();
        setEditDescription(false);
        // notice("Update successful");
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handlePriorityChange = (selected_priority) => {
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      priority: selected_priority
    })
    .then(function(response){
      if(response.data.success){
        fetchTask();
        setEditDescription(false);
        refetchData();
        // notice("Update successful");
        // Update the task state immediately
        setTask(prevTask => ({
          ...prevTask,
          priority: response.data.task_data.priority
        }));
      } else {
        notice("Failed to update priority");
      }
    })
    .catch(function(error){
      console.log(error);
      notice("An error occurred");
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const handleDeadlineChange = (date) => {

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      deadline: date
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setTask(prevTask => ({
          ...prevTask,
          deadline: response.data.task_data.deadline
        }));
        fetchTask();
        setEditDescription(false);
        refetchData();
        // notice("Update successful");
      } else {
        notice("Failed to update deadline");
      }
    })
    .catch(function(error){
      console.log(error);
      notice("An error occurred");
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const handleTitle = (e) => {
    // Perform any actions needed when editing is finished
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      title: e.target.textContent
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        fetchTask();
        // notice("Update successful");
      }
    })
  };
  
  const task_comments_list = taskComments.map(task_comment => {
    return(
      <TaskComment task_comment={task_comment} taskComments={taskComments} setTaskComments={setTaskComments} refetchData={fetchTaskComments} project_id={project_id} task={task} users={usersStrings} projectAuthorizations={projectAuthorizations} fetchTask={fetchTask}/>
    )
  })

  const replays_list = replays.map(replay => {
    return(
      <div id={`task-replay-${replay.replay_id}`} className="task-draggable task-droppable background-3" data-parent_id={replay.parent_id} data-replay_id={replay.replay_id}>
        <TaskReplay replay={replay}  refetchData={() => {fetchTaskComments(); fetchTask();}} task_id={task_id} project_id={project_id || match.params.project_id}/>
        <div className="task-droppable-message animated fadeIn">
          <i class="far fa-plus mr-8"></i>Drag on top another file to add new version
        </div>
      </div>
    )
  });

  const field_data_list = task?.field_data?.filter(item => !["line", "header"].includes(item.type)).map(field => {
    return(
      <React.Fragment key={field.id}>
        <div className="section-wrapper mb-15 field border-all default-padding border-radius mb-10">
          <div className="form-header-input">Additional details</div>
          <div className="task-form-edit mt-10" style={{marginLeft: -10}}>
            <Field field={field} project_id={project_id || match.params.project_id} refetchData={() => {refetchData(); fetchTask();}} task_id={task_id} task={task} setTask={setTask} users={usersStrings}/> 
          </div>
        </div>
      </React.Fragment>
    )
  });

  const section_list = task?.section_field_data?.map(section => (
    <div className="section-wrapper mb-15 field border-all default-padding border-radius mb-10" key={section.token}>
      <div className="form-header-input">{section.title}</div>
      <div class="form-subheading-input">
        {section.description}
      </div>
      {section.fields?.filter(item => !["line", "header"].includes(item.type)).map(field => (
        <div className="task-form-edit mt-10" key={field.id} style={{marginLeft: -10}}>
          <Field 
            field={field} 
            project_id={project_id || match.params.project_id} 
            refetchData={() => {refetchData(); fetchTask();}} 
            task_id={task_id} 
            task={task} 
            setTask={setTask} 
            users={usersStrings}
            section_id={section.token}
          /> 
        </div>
      ))}
    </div>
  ));

  const changeColumn = (column_id) => {
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}/update_position`, {
      column_id: column_id,
      position: 0,
    })
    .then(function (response) {
      // console.log(response);
      if (response.data.success && response.data.task_data) {
        setTask(prevTask => ({
          ...prevTask,
          column: response.data.task_data.column
        }));
        refetchData();
      } else {
        notice("Failed to update column");
      }
    })
    .catch(function (error) {
      console.log(error);
      notice("An error occurred while updating the column");
    });
  }

  const columns_list = columns.filter(column => column.token !== task?.column.token && hasPermission(column.stage)).map(column => (
    <li key={column.token}>
      <a disabled={column.full} className={`stage-dropdown-a display-flex align-items-center justify-content-between ${column.full ? "disabled" : ""}`} onClick={() => changeColumn(column.token)}>
        <div className="display-flex align-items-center">
          <StageIcon stage={column.stage}/>{column.title}
        </div>
        {column.full && <span className="badge background-active ml-15">Full</span>}
      </a>
    </li>
  ));

  const subtask_columns_list = columns.filter(column => hasPermission(column.stage)).map(column => (
    <li key={column.token}>
      <a disabled={column.full} className={`stage-dropdown-a display-flex align-items-center justify-content-between ${column.full ? "disabled" : ""}`} onClick={() => setQuickSubtaskColumn(column)}>
        <div className="display-flex align-items-center">
          <StageIcon stage={column.stage}/>{column.title}
        </div>
        {column.full && <span className="badge background-active ml-15">Full</span>}
      </a>
    </li>
  ));

  const markFinished = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}`, {
      finished: !task.finished
    })
    .then(function(response){
      if(response.data.success){
        if(!window.location.href.includes(`/o/${match.params.organization_id}/dashboard`)){
          refetchData();
          notice("Saved")
        }
        fetchTask();
        // Immediately update the task.finished state
        setTask(prevTask => ({
          ...prevTask,
          finished: !prevTask.finished
        }));
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occurred");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }
  
  const updatePrivacy = (boolean) => {
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}`, {
      private: boolean
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Updated visability")
        refetchData();
        fetchTask();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {

    });
  }

  // Subtask reorder
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    // Create a copy of the current subtasks list
    const newSubtasks = Array.from(subtasks);
  
    // Remove the dragged subtask from its original position
    const [removed] = newSubtasks.splice(result.source.index, 1);
  
    // Insert the dragged subtask into the destination position
    newSubtasks.splice(result.destination.index, 0, removed);
  
    // Update the state with the reordered subtasks
    setSubtasks(newSubtasks);

    // Send Axios POST request to update positions of subtasks in the backend
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${removed.token}/update_subtask_position`, { 
      task_id: removed.token,
      newPosition: result.destination.index,
      newSubtasks: newSubtasks
    })
      .then((response) => {
        // notice("Moved successfully")
        refetchData();
      })
      .catch((error) => {
        // Handle error
      });
  };

  const task_labels_list = taskLabels.map(task_label => {
    return(
      <span className={`badge box-shadow background-active mb-5`} style={{background: `#${task_label.label.color}`, color: "white"}}>
        {task_label.label.title}
      </span>
    )
  });

  const picked_task_labels_list = taskLabels.map(task_label => {
    return(
      <TaskLabelPicked task_label={task_label} task={task} refetchData={() => {refetchData();}} setArray={setTaskLabels}/>
    )
  })
  
  const labels_list = labels.filter(label => !taskLabels.some(taskLabel => taskLabel.label.id === label.id)).map(label => {
    return(
      <TaskLabels label={label} task={task} refetchData={() => {refetchData();}} setArray={setTaskLabels} taskLabels={taskLabels}/>
    )
  })

  const labelMenuAnchorRef = useRef(null);
  const { show } = useContextMenu({
    id: `task-${task_id}-labels-menu`
  });
  function displayMenu(e){
    e.preventDefault();
    e.stopPropagation();
    
    if (labelMenuAnchorRef.current) {
      const anchorRect = labelMenuAnchorRef.current.getBoundingClientRect();
      
      show({
        event: e,
        position: {
          x: anchorRect.left,
          y: anchorRect.bottom
        }
      });
    } else {
      // Fallback to showing menu at event coordinates if ref is not available
      show({ event: e });
    }
  }
  
  const clearCacheAndReload = async () => {
    await localForage.removeItem(`task-show-${task_id}-v2`);
    window.location.reload();
  }

  const createSubtaskQuick = (e) => {
    e.preventDefault();
    setDisabled(true);
    const title = quickSubtaskInput; 
    setQuickSubtaskInput("");

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/`, {
      title: title,
      parent_task_id: task_id,
      column_id: quickSubtaskColumn?.token
    })
    .then(function(response){
      if(response.data.success){
        // refetchData();
        // fetchTask();
        // setAddSubtask(false);
        setSubtasks(prevArray => [...prevArray, response.data.task_data]);
      }
    })
    .catch(function(error){
      console.log(error);
    });
  };

  return(
    loaded ? (
      <div className="full-card-wrapper">
        <PanelGroup className="full-card-container" direction="horizontal">
          <Panel defaultSize={60} minSize={20} className="full-card-left-panel">
            <div className="full-card-left">
              <Tabs>
                <div className="full-card-header display-flex align-items-center justify-content-between pr-15 pb-15">
                  <div className="display-flex align-items-center gap-8">
                    <TabList className="react-tabs-custom" style={{width: "max-content"}}>
                      <Tab><i className="fal fa-home mr-5"></i>Overview</Tab>
                      <Tab><i className="fal fa-file-alt mr-5"></i>Details</Tab>
                    </TabList>

                      <div class="btn-group">
                        {task.private && (
                          <React.Fragment>
                            <a type="button" class="color-red dropdown-toggle background-hover btn btn-small" data-toggle="dropdown">
                              <i class="fal fa-lock"></i>
                            </a>
                          </React.Fragment>
                        )}
                      <ul class="dropdown-menu animated fadeInUp" role="menu">
                        <li><a onClick={() => updatePrivacy(false)}><i class="far fa-globe-americas mr-5"></i>{t("Everyone")}</a></li>
                        <li><a onClick={() => updatePrivacy(true)}><i class="fal fa-lock mr-5"></i>{t("Team only")}</a></li>
                      </ul>
                    </div>
                  </div>

                  <div className="display-flex align-items-center gap-8">
                    {subtasks.length > 0 && 
                      <div onClick={() => {setShowSubtasks(true); document.getElementById("taskSubtasks").scrollIntoView({behavior: "smooth", block: "start"})}} className="circular-progress-bar cursor-pointer mr-10" style={{ width: '30px', height: '30px' }}>
                        <CircularProgressbar 
                          value={subtasks.filter(subtask => subtask.column?.stage === "done").length / subtasks.length * 100} 
                          text={`${subtasks.filter(subtask => subtask.column?.stage === "done").length}/${subtasks.length}`}
                          styles={{
                            path: {
                              stroke: '#4169e1',
                            },
                            text: {
                              fontSize: '30px',
                              fill: '#4169e1'
                            },
                          }}
                        />
                      </div>
                    }

                    {organizationUser &&
                      <>
                        {testers.some(tester => tester.kind === 'Timesheets') && 
                          <TaskTimeTracker project_id={project_id || match.params.project_id} task={task}/>
                        }

                        <div class="btn-group">
                          <a type="button" class="btn btn-small opacity-5 opacity-10-hover background-hover color-1 dropdown-toggle" data-toggle="dropdown">
                            <i className="fas fa-ellipsis-v"></i>
                          </a>
                          <ul class="dropdown-menu pull-right animated fadeInUp" role="menu">
                            <li><a onClick={() => updatePrivacy(true)}><i class="fal fa-lock mr-5"></i>{t("Team only")}</a></li>
                            {/* <li><a onClick={() => notice("Feature coming soon!")}><i class="far fa-trash-alt mr-5"></i>Delete</a></li> */}
                          </ul>
                        </div>
                      </>
                    }
                    {/* <a className="btn btn-primary btn-small"><i className="far fa-check-square mr-5"></i>Mark finished</a> */}
                  </div>
                </div>

                <div className="full-card-left-body default-padding">
                  <TabPanel>
                    <div className="field display-flex flex-direction-column pl-5 pr-5" style={{gap: "20px", marginTop: 10}}>
                      <div className="display-flex align-items-center justify-content-between width-100-percent task-fullcard-quick-access-wrapper">
                        {/* Title */}
                        <div className="row full-card-quick-access-wrapper display-flex align-items-center">
                          <div className="col-4 full-card-quick-access-label font-13">
                            {t("Title")}
                          </div>
                          
                          <div className="col-8">
                            <div className="truncate full-card-quick-access-value" contentEditable onBlur={handleTitle} style={{width: "calc(100% - 50px)"}}>
                              {task.title}
                            </div>
                          </div>
                        </div>

                        {/* Due Date */}
                        <div className="row full-card-quick-access-wrapper display-flex align-items-center">
                          <div className="col-4 full-card-quick-access-label font-13 position-relative">
                            {t("Created")}
                          </div>
                          
                          <div className="col-8 full-card-quick-access-value opacity-10-hover">
                            {task.created_at}
                          </div>
                        </div>
                      </div>

                      <div className="display-flex align-items-center justify-content-between width-100-percent task-fullcard-quick-access-wrapper">
                        {/* Stage */}
                        <div className="row full-card-quick-access-wrapper display-flex align-items-center">
                          <div className="col-4 full-card-quick-access-label font-13">
                            {t("Stage")}
                          </div>
                          
                          <div className="col-8 full-card-quick-access-value">
                            <div class="btn-group">
                              <div type="button" class="color-1 dropdown-toggle cursor-pointer display-flex align-items-center" data-toggle={hasPermission("update_stage_task") ? "dropdown" : ""}>
                                <StageIcon stage={task.column.stage}/>{task.column.title}
                              </div>

                              <ul class="dropdown-menu animated fadeInUp" role="menu">
                                {columns_list}
                              </ul>
                            </div>
                          </div>
                        </div>

                        {/* Due Date */}
                        <div className="row full-card-quick-access-wrapper display-flex align-items-center">
                          <div className="col-4 full-card-quick-access-label font-13">
                            {t("Due date")}
                          </div>
                          
                          <div className="col-8 full-card-quick-access-value">
                            <div style={{display: "flex", alignItems: "center"}}>
                              {!clientPortal ? (
                                <div className="badge cursor-pointer background-hover-2" style={{marginLeft: "-13px"}}>
                                  <DatePicker
                                    selected={task.deadline}
                                    onChange={(date) => handleDeadlineChange(date)}
                                    className="task-question-answer-input background-hover-2 cursor-pointer opacity-100"
                                    placeholderText="Empty"
                                    disabled={!hasPermission("deadline_task")}
                                  />
                                </div>
                              ):(
                                <>
                                  {task.deadline_short}
                                </>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>
                      
                      <div className="display-flex align-items-flex-start justify-content-between width-100-percent task-fullcard-quick-access-wrapper">
                        {/* Priority */}
                        <div className="row full-card-quick-access-wrapper display-flex align-items-center">
                          <div className="col-4 full-card-quick-access-label font-13">
                            {t("Priority")}
                          </div>
                          
                          <div className="col-8 full-card-quick-access-value">
                            <div class="btn-group">
                              {(task.priority != null && task.priority != "") ? (
                                <>
                                  <span class="cursor-pointer dropdown-toggle display-flex align-items-center" data-toggle="dropdown">
                                    <span class="badge-label-color" style={{width: 15, height: 15, background: `${task.priority === "High" ? "#dc3545" : task.priority === "Medium" ? "#ffab58" : "#4169e1"}`}}></span>
                                    {task.priority}
                                  </span>
                                </>
                              ):(
                                <div class="badge task-form-answer-empty dropdown-toggle" data-toggle="dropdown">
                                  Empty
                                </div>
                              )}
                              
                              <ul class="dropdown-menu animated fadeInUp" role="menu">
                                <li>
                                  <a onClick={() => handlePriorityChange(null)}>
                                    <i className="far fa-circle"></i> No priority
                                    {task.priority === null && 
                                      <i className="fal fa-check ml-8 font-13"></i>
                                    }
                                  </a>
                                </li>

                                <li>
                                  <a onClick={() => handlePriorityChange("High")}>
                                    <i className="fas fa-circle color-red"></i>High
                                    {task.priority === "High" && 
                                      <i className="fal fa-check ml-8 font-13"></i>
                                    }
                                  </a>
                                </li>

                                <li>
                                  <a onClick={() => handlePriorityChange("Medium")}>
                                    <i className="fas fa-circle color-orange"></i>Medium
                                    {task.priority === "Medium" && 
                                      <i className="fal fa-check ml-8 font-13"></i>
                                    }
                                  </a>
                                </li>

                                <li>
                                  <a onClick={() => handlePriorityChange("Low")}>
                                    <i className="fas fa-circle color-blue"></i>Low
                                    {task.priority === "Low" && 
                                      <i className="fal fa-check ml-8 font-13"></i>
                                    }
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        
                        {/* created by */}
                        <div className="row full-card-quick-access-wrapper display-flex align-items-center">
                          <div className="col-4 full-card-quick-access-label font-13 position-relative">
                            {t("Created by")}
                          </div>
                          
                          <div className="col-8 full-card-quick-access-value">
                            <TaskOwner refetchData={fetchTask} task={task} project_id={project_id}/>
                          </div>
                        </div>
                      </div> 

                      <div className="display-flex align-items-flex-start justify-content-between width-100-percent task-fullcard-quick-access-wrapper">
                        {/* Stage */}
                        <div className="row full-card-quick-access-wrapper display-flex">
                          <div className="col-4 full-card-quick-access-label font-13 position-relative" style={{top: 5}}>
                            {t("Assigned to")}
                          </div>
                          
                          <div className="col-8 full-card-quick-access-value">
                            <TaskUsers usersStrings={usersStrings} task={task} users={task.users} refetchData={() => {fetchTask();refetchData();}} organizationUser={organizationUser} project_id={project_id} setTask={setTask}/>
                          </div>
                        </div>

                        {/* Labels */}
                        <div className="row full-card-quick-access-wrapper display-flex align-items-flex-start">
                          <div className="col-4 full-card-quick-access-label font-13 position-relative" style={{top: 5}}>
                            {t("Labels")}
                          </div>
                          
                          <div className="col-8 full-card-quick-access-value hover-parent-opacity cursor-pointer" ref={labelMenuAnchorRef} onClick={(e) => {
                            if (!clientPortal && hasPermission("new_task_label")) {
                              displayMenu(e);
                            }
                          }} data-html={true} data-tip="Add labels <span class='keybind'>L</span>">
                            {task_labels_list}
                
                            {taskLabels.length === 0 && 
                              <span className="badge task-form-answer-empty" ref={labelMenuAnchorRef} onClick={displayMenu}>Empty</span>
                            }
                            
                            {hasPermission("new_task_label") && 
                              <Portal>
                                <Menu id={`task-${task.token}-labels-menu`}>
                                  {labels.length > 0 &&
                                    <>
                                    {taskLabels.length > 0 && 
                                      <>
                                        {picked_task_labels_list}
                                        <Separator />
                                      </>
                                    }
                                      {labels_list}
                                    </>
                                  }

                                  <Item onClick={() => setOpenNewLabel(true)}>
                                    <i className="far fa-plus mr-8"></i>New Label
                                  </Item>
                                </Menu>
                              </Portal>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Description */}
                    <div className="field pl-5 pr-5" style={{marginTop: 30}}>
                      <TipTap comment={task.description} setComment={setDescription} task={task} project_id={project_id || match.params.project_id} users={usersStrings} refetchData={() => {fetchTask();}} loaded={loaded} setTask={setTask}/>
                    </div>
                    
                    {/* Subtasks */}
                    <div className="field" id="taskSubtasks" style={{marginTop: 30}}>
                      <div className="row mt-25 mb-25">
                        <div className="display-flex justify-content-between align-items-center width-100-percent mb-5">
                          <a className="color-1" onClick={() => setShowSubtasks(!showSubtasks)}>
                            {t("Subtasks")}
                          </a>

                          <div className="">
                            {hasPermission("create_subtasks") && 
                              <div className="display-flex align-items-center">
                                <div className="add-subtasks-btn">
                                  <div class="btn-group mr-8">
                                    {!clientPortal && 
                                      <AddTaskTemplate columns={columns} refetchData={() => {refetchData(); fetchTask();}} project_id={project_id || match.params.project_id} task_id={task.token} setSubtasks={setSubtasks}/>
                                    }
                                  </div>

                                  <AiSubtasks refetchData={() => {refetchData(); fetchTask();}} column={task.column} parent_task_id={task.token} closeModal={() => setOpenDefaultQuestions(false)} projectAuthorizations={projectAuthorizations} project_id={project_id} users={usersStrings} setArray={setSubtasks}/>
                                </div>
                                
                                {/* <a className="btn btn-small color-1 background-hover" onClick={() => setOpenDefaultQuestions(true)}>
                                  <i className="far fa-plus"></i>
                                </a> */}
                                <a data-html={true} data-tip="Add subtask <span class='keybind'>S</span>" className="btn btn-small color-1 background-hover" onClick={() => {setAddSubtask(!addSubtask); setQuickSubtaskColumn(columns?.[0])}}>
                                  <i className="far fa-plus"></i>
                                </a>
                              </div>
                            }
                          </div>
                        </div>

                        {showSubtasks && 
                          <>
                            <DragDropContext onDragEnd={handleDragEnd}>
                              <Droppable droppableId="droppable">
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {subtasks.map((subtask, index) => (
                                      <Draggable key={subtask.token} draggableId={subtask.token} index={index}>
                                        {(provided) => (
                                          <div className={`row hover-parent-opacity position-relative subtask-list-item align-items-center ${index === selectedSubtaskIndex ? 'selected-subtask' : ''}`}  ref={provided.innerRef} {...provided.draggableProps}>
                                            <div {...provided.dragHandleProps} className="position-absolute hover-child-opacity subtask-drag-anchor" data-tip="Drag to change position" style={{width: 20, left: -15, top: 13 }}>
                                              <VscGripper />
                                            </div>

                                            <SubTask 
                                              setSubtasks={setSubtasks}
                                              subtask={subtask} 
                                              columns={columns} 
                                              fetchTask={fetchTask}
                                              refetchData={() =>{refetchData()}} 
                                              organizationUser={organizationUser} 
                                              project_id={project_id} 
                                              openSubtaskToken={openSubtaskToken}
                                              setOpenSubtaskToken={setOpenSubtaskToken}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>

                            <Modal open={openDefaultQuestions} onClose={() => setOpenDefaultQuestions(false)} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
                              <DefaultQuestions refetchData={() => {refetchData(); fetchTask();}} column={task.column} parent_task_id={task.token} closeModal={() => setOpenDefaultQuestions(false)} projectAuthorizations={projectAuthorizations} project_id={project_id} usersStrings={usersStrings} setArray={setSubtasks}/>
                            </Modal>
                          </>
                        }

                        {addSubtask && 
                          <div className="add-subtask-quick-wrapper position-relative">
                            <div className="add-subtask-quick-input-wrapper display-flex align-items-center border-all border-radius background-3 background-hover default-padding">
                              <div class="btn-group">
                                <div type="button" class="pl-5 color-1 dropdown-toggle cursor-pointer display-flex align-items-center" data-toggle={hasPermission("update_stage_task") ? "dropdown" : ""}>
                                  <StageIcon stage={quickSubtaskColumn?.stage}/>
                                </div>

                                <ul class="dropdown-menu animated fadeInUp" role="menu">
                                  {subtask_columns_list}
                                </ul>
                              </div>

                              <form style={{width: "100%"}} className="" onSubmit={createSubtaskQuick}>
                                <input 
                                  required="true" 
                                  placeholder="Title and then press enter key" 
                                  autoFocus 
                                  onChange={(e) => setQuickSubtaskInput(e.target.value)} 
                                  value={quickSubtaskInput} 
                                  className="add-subtask-input"
                                  onKeyDown={(e) => {
                                    if (e.key === 'Escape') {
                                      setAddSubtask(false);
                                      setQuickSubtaskInput('');
                                      setQuickSubtaskColumn(columns[0]);
                                    }
                                  }}
                                />
                                <div className="add-subtask-buttons">
                                  <a onClick={() => {setAddSubtask(false); setQuickSubtaskInput(""); setQuickSubtaskColumn(columns[0])}} className="btn btn-small color-1 background-active background-hover-2 add-subtask-button-cancel">Cancel</a>
                                  <button type="submit" className="btn btn-small btn-primary color-1 background-hover ml-5">Submit</button>
                                </div>
                              </form>
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    <div className="" style={{minHeight: 300}}>
                      <div className="">
                        <div className="row">
                          <div className="daas-card-reviews-cta-wrapper">
                            <div className="font-weight-500 mb-5">
                              {/* <i class="far color-3 fa-upload mr-5"></i> */}
                              {t("Reviews requests")}
                              {/* <p className="font-12 opacity-6">{t("Share files and websites for click-to-comment reviewing.")}</p> */}
                            </div>

                            {(!clientPortal && hasPermission("new_task_replay")) && 
                              <Upload refetchData={fetchTask} task_id={task.token} project_id={project_id || match.params.project_id} task={task}/>
                            }
                          </div>
                          
                          {settingVersions ? (
                            <p className="text-center">
                              <i className="fas fa-spinner fa-spin mr-8"></i>Adding version...
                            </p>
                          ):(
                            <div className="daas-card-reviews-replays">
                              {replays.length > 0 ? (
                                <>
                                {replays_list}
                                </>
                              ):(
                                <p className="text-center opacity-4 font-10">{t("You're all caught up on review requests!")}</p>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  
                    <TabPanel>
                      {section_list}
                      {field_data_list}

                      <div className="field mt-10">
                        <NewProperty refetchData={fetchTask} task={task} setTask={setTask} project_id={project_id || match.params.project_id}/>
                      </div>
                    </TabPanel>

                </div>
              </Tabs>
            </div>
          </Panel>

          <PanelResizeHandle className="resize-handle" />

          <Panel defaultSize={40} minSize={20} maxSize={80}>
            <div className="full-card-right">
              <Tabs>
                <div className="full-card-header">
                  <TabList className="react-tabs-custom mb-15" style={{width: "max-content"}}>
                    <Tab><i className="fal fa-comment mr-5"></i>{t("Comments")}</Tab>
                    <Tab><i className="fal fa-upload mr-5"></i>{t("Files")}</Tab>
                  </TabList>
                </div>

                <div className="full-card-right-body pl-15 pr-15 pt-15">
                  <TabPanel>
                    <div className="full-card-comments-wrapper" id="full-card-comments-wrapper">
                      {(!loadingComments && limit < 1000 && taskCommentsSize > limit) && 
                        <div className="field text-center">
                          <button className="btn btn-small btn-primary-outline color-black-always" onClick={() => {setLimit(10000);}}>
                            {loadingComments ? (
                              <i className="fas fa-spinner fa-spin mr-5"></i>
                            ):(
                              <i className="fas fa-plus mr-5"></i>
                            )}
                            {t('Load all comments')}
                          </button>
                        </div>
                      }

                      <div>
                        {taskCommentsLoaded ? (
                          <>
                            {task_comments_list}
                            {taskComments.length === 0 && 
                              <div className="task-comments-empty">
                                <div className="display-flex flex-direction-column align-items-start" style={{maxWidth: 400}}>
                                  <div className="display-flex align-items-center justify-content-center background-active border-radius border-all default-padding">
                                    <MdOutlineMarkUnreadChatAlt size={20} />
                                  </div>
                                  <div className="mb-0 font-weight-500 mt-5">Start a conversation</div>
                                  <p className="opacity-6 font-12">Share updates, ask questions, or discuss this task with others.
                                  Use @mentions to notify specific people.</p>
                                </div>
                              </div>
                            }
                            <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
                          </>
                        ):(
                          <React.Fragment>
                            <Skeleton className="skeleton-white mb-15" style={{width: "100%", height: "50px"}}/>
                            <Skeleton className="skeleton-white mb-15" style={{width: "100%", height: "50px"}}/>
                            <Skeleton className="skeleton-white mb-15" style={{width: "100%", height: "50px"}}/>
                          </React.Fragment>
                        )}
                      </div>
                      
                      {hasPermission("create_task_comments") && 
                        <div className="background_three" style={{position: "sticky", bottom: 0, left: 0, right: 0}}>
                          <Form task={task} refetchData={fetchTaskComments} currentUser={currentUser} setLimit={setLimit} projectAuthorizations={projectAuthorizations} project_id={project_id} users={usersStrings}  taskComments={taskComments} setTaskComments={setTaskComments}/>
                        </div>
                      }
                    </div>
                  </TabPanel>

                  <TabPanel>
                    {/* Review requests */}
                    <div className="full-card-comments-wrapper">
                      <div className="row">
                        <TaskReplaysIndex project_id={project_id || match.params.project_id} task_id={task.token}/>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </Panel>
        </PanelGroup>
      </div>
    ):(
      <div className="text-center large-padding">
        <i className="fas fa-spinner fa-spin color-black-always font-21"></i>
        {loadingHanged && (
          <div className="mt-15">
            <p className="mb-10 color-red">Loading is taking longer than expected.</p>
            <button 
              className="btn btn-small btn-danger" 
              onClick={clearCacheAndReload}
            >
              Clear Cache & Retry
            </button>
          </div>
        )}
      </div>
    )
  )
}

export default FullCardV2;