import React , { useState, useEffect, useRef } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Files from "./Files.js";
import ReactTooltip from 'react-tooltip';
import DatePicker from "react-datepicker";
import FilestackAttachements from "../TaskForms/FilestackAttachements.js";
import { PickerOverlay, PickerInline } from 'filestack-react';
import TipTap from "../../../../Shared/TipTap.js";
import Select from 'react-select'; // Add this import

const Field = ({project_id, field, task_id, refetchData, task, setTask, usersStrings, section_id}) => {
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState(field.value);
  const editFieldRef = useRef(null);
  const [openFilepicker, setOpenFilepicker] = useState(false);
  
  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleUpdate();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      handleCancel();
    }
  };

  const handleUpdate = () => {
    if (editValue !== field.value) {
      // Update local state immediately
      const updatedTask = {...task};
      const updatedField = updatedTask.field_data.find(f => f.id === field.id);
      if (updatedField) {
        updatedField.value = editValue;
        setTask(updatedTask);
      }

      if (section_id) {
        const updatedSection = updatedTask.section_field_data.find(s => s.token === section_id);
        if (updatedSection) {
          updatedSection.fields.find(f => f.id === field.id).value = editValue;
          setTask(updatedTask);
        }
      }

      // Then update on the server
      updateFieldData(editValue);
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditValue(field.value);
    setIsEditing(false);
  };
  
  // Function to update field data
  const updateFieldData = (newValue) => {
    axios
      .post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}/update_field_data`, {
        field_token: field.id,
        value: newValue,
        section_id: section_id, 
        field: field
      })
      .then(function (response) {
        if (response.data.success) {
          refetchData();
          // Do something after successfully updating field data
        } else {
          // Handle error if update fails
        }
      })
      .catch(function (error) {
        console.log(error);
        // Handle error
      });
  };

  const renderEditableField = () => {
    const commonProps = {
      value: editValue,
      onChange: (e) => setEditValue(e.target.value),
      onKeyDown: handleKeyDown,
      className: "field-input-form-control",
      ref: editFieldRef,
      autoFocus: true
    };

    switch (field.type) {
      case 'text':
        if (field.kind === 'date') {
          return (
            <>
            </>
          );
        }
        return <input autoFocus type="text" {...commonProps} />;
      case 'link':
        return <input autoFocus type="link" {...commonProps} />;
      case 'textarea':
        return (
          <div className="animated fadeIn">
            <TipTap comment={editValue} setComment={setEditValue} users={usersStrings} classNames="field-tiptap"/>
          </div>
        );
      case 'checkbox':
        const options = field.options.map(option => ({ value: option, label: option }));
        return (
          <Select
            isMulti
            options={options}
            value={editValue.map(v => ({ value: v, label: v }))}
            onChange={(selectedOptions) => {
              const newValue = selectedOptions.map(option => option.value);
              setEditValue(newValue);
            }}
            className="react-select-task-form-options"
            classNamePrefix="react-select"
          />
        );
      case 'radio':
        const radio_options = field.options.map(option => ({ value: option, label: option }));
        return (
          <Select
            options={radio_options}
            value={{ value: editValue, label: editValue }}
            onChange={(selectedOption) => {
              setEditValue(selectedOption.value);
            }}
            className="react-select-task-form-options"
            classNamePrefix="react-select"
          />
        );
      default:
        return <span>Editing not supported for this field type</span>;
    }
  };

  const uploadAttachements = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
  
    // Update the task's field value
    const updatedTask = {...task};
    const updatedField = updatedTask.field_data.find(f => f.id === field.id);
    if (updatedField) {
      updatedField.value = Array.isArray(updatedField.value) ? [...updatedField.value, ...filesUploaded] : filesUploaded;
      setTask(updatedTask);
    }
  
    // Update the server
    updateFieldData(updatedField.value);
  };
  

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="row full-card-info-wrapper">
        <div className="col-12 pr-15 task-question-answer-label font-weight-500 font-14">
          <div className="display-flex gap-8" data-tip={field.label}>
            {field.label}
          </div>
        </div>

        <div className="col-12 task-question-answer opacity-5 cursor-pointer font-13" 
          onClick={(e) => {
            if(e.target.classList.contains('task-question-answer')){
              if (field.type === "upload") {
                setOpenFilepicker(true);
              } else {
                setIsEditing(true);
              }
            }
          }}
        >
          {isEditing ? (
            <div style={{width: "100%"}} ref={editFieldRef}>
              {renderEditableField()}
              <div className="mt-2">
                <button className="btn btn-primary btn-small mr-8" onClick={handleUpdate}>
                  Update
                </button>
                
                <button className="btn btn-secondary background-hover btn-small background-active" onClick={handleCancel}>
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div className="cursor-pointer" onClick={field.type != "upload" ? handleEdit : null}>
              {field.type === "upload" && 
                <div className="mt-5 display-flex align-items-center">
                  {typeof field.value === 'string' ? (
                    // If field.value is a string, render each link separately
                    field.value.split(', ').map((link, index) => (
                      <p key={index}>
                        <a href={link} target="_blank">{link}</a>
                      </p>
                    ))
                  ) : (
                    <>
                      <a className="btn btn-small color-1 background-hover border-all mr-5" onClick={() => setOpen(true)}>
                        <i className="far fa-file-alt mr-5"></i> {field.value.length} Uploads
                      </a>
                      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                        <Files attachements={field.value || []} zipFileName={field.label}/>
                      </Modal>
                    </>
                  )}
                  
                  <a className="btn btn-small color-1 background-hover border-all ml-5" onClick={() => setOpenFilepicker(true)}><i class="far fa-upload mr-5"></i>Upload</a>
                  {openFilepicker && 
                    <Modal open={openFilepicker} onClose={() => setOpenFilepicker(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                      <PickerInline
                        apikey={"AKVhxMbEQhkOIryqBvSEQz"}
                        onSuccess={(res) => console.log(res)}
                        onUploadDone={(res) => {
                          uploadAttachements(res);
                          setOpenFilepicker(false);
                        }}
                        clientOptinos={{
                          sessionCache:true
                        }}
                        pickerOptions={{
                          onClose: () => {
                            setOpenFilepicker(false);
                          }, 
                          fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
                          maxFiles: 15, 
                          storeTo: {
                            location: 'gcs',
                            path: '/',
                            container: 'queue-filestack'
                          }
                        }}
                      />
                    </Modal>
                  }
                </div>
              }

              {(field.value == "" && field.kind != "date" && field.type != "upload") && <span className="opacity-4">Empty</span>}
    
    
              {field.kind == "link" && 
                <p>
                  <a href={field.value} target="_blank">{field.value}</a>
                </p>
              }
    
              {(field.kind == "text" && field.type == "text") &&
                <p>{field.value}</p>
              }
    
              {field.kind == "textarea" && 
                <p dangerouslySetInnerHTML={{ __html: field.value }} />
              }
    
              {field.type == "textarea" && 
                <p dangerouslySetInnerHTML={{ __html: field.value }} />
              }
    
              {field.type == "checkbox" && 
                <p className="mt-5">
                  {field.value.map(item => {
                    return(
                      <span key={item} className="badge font-14 mr-8 background-3 mb-5 border-all">{item}</span>
                    )
                  })}
                </p>
              }
    
              {field.type == "radio" && 
                <p>
                  <span className="badge font-14 mr-8 background-3 mb-5 border-all">{field.value}</span>
                </p>
              }
            </div>
          )}
          
          {field.kind == "date" && 
            <DatePicker
              selected={editValue ? new Date(editValue) : field.value}
              onChange={(date) => {setEditValue(date); console.log(editValue); updateFieldData(date)}}
              className="input-no-border font-14"
              placeholderText="Set Date"
            />
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default Field;