import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import TaskTemplate from "./TaskTemplate.js";
import Form from "./Form.js";

const AddTaskTemplate = ({refetchData, project_id, columns, task_id, setSubtasks}) => {
  const {organizationAuthorizations, taskTemplates, fetchTaskTemplates, setTaskTemplates} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTaskTemplateForm, setOpenTaskTemplateForm] = useState(false);

  const task_templates_list = taskTemplates?.map(task_template => {
    return(
      <TaskTemplate setTaskTemplates={setTaskTemplates} taskTemplates={taskTemplates} taskTemplate={task_template} refetchData={refetchData} project_id={project_id || match.params.project_id} column={columns[0]} closeModal={() => setOpenTaskTemplateForm(false)} parent_task_id={task_id} setSubtasks={setSubtasks}/>
    )
  });

  return(
    <React.Fragment>
      <a type="button" class="btn btn-small background-hover color-1 dropdown-toggle" data-toggle="dropdown">
        <i class="fal fa-tasks-alt mr-8"></i>Templates
      </a>

      <ul class="dropdown-menu animated fadeInUp pull-right" role="menu" style={{overflow: "auto", maxHeight: "400px"}}>

        <li class="divider"></li>
        <label className="font-12 ml-15 mb-0">Task templates</label>
        {task_templates_list}
        <li class="divider"></li>
        <li><a onClick={() => setOpenTaskTemplateForm(true)}><i class="far fa-plus mr-5"></i>New Template</a></li>
        <Modal open={openTaskTemplateForm} onClose={() => {setOpenTaskTemplateForm(false);}} className="modal-body-white" classNames={{modal: 'width-800'}} center refetchData={fetchTaskTemplates}>
          <Form closeModal={() => setOpenTaskTemplateForm(false)}/>
        </Modal>
      </ul>
    </React.Fragment>
  )
}

export default AddTaskTemplate;