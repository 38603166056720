import React , { useState, useContext, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom'
import {UserContext} from "../Shared/UserContext.js";
import axios from "axios";
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-responsive-modal';
import ImportSubscriptions from "../Onboardings/Sellers/ImportSubscriptions.js";
import Btn from "../Shared/Btn.js";
import {ProjectContext} from "../Projects/ProjectContext.js";
import Select from 'react-select';
import Filestack from "./Filestack.js";
import Toggle from 'react-toggle';
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import { useTranslation } from 'react-i18next';
import { AnimatePresence, motion } from "framer-motion";

const New = ({onCloseModal, organization_id, chatroom_id, buyer, seller, refetchData, parent_project_id}) => {
  const { t } = useTranslation();
  const {organizationAuthorizations} = useContext(OrganizationContext);
  const match = useRouteMatch();
  const history = useHistory();
  const {groups, taskboardTemplates} = useContext(ProjectContext);
  const [disabled, setDisabled] = useState(false)
  const [title, setTitle] = useState("")
  const currentUser = useContext(UserContext)
  const [isPrivate, setIsPrivate] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [stripeAccountPresent, setStripeAccountPresent] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [logo, setLogo] = useState(null);
  const [open, setOpen] = useState(false);
  const [clientEmail, setClientEmail] = useState(null);
  const [inviteEntireTeam, setInviteEntireTeam] = useState(false);
  const [advanced, setAdvanced] = useState(false);
  const [selectedTaskboardTemplate, setSelectedTaskboardTemplate] = useState(null);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const handleSubmit = async (evt) => {
    setDisabled(true)
    evt.preventDefault();
    try {
      const data = formData("new_project");
      data.title = title; 
      data.chatroom_id = chatroom_id;
      data.private = isPrivate;
      data.service_id = selectedService;
      data.group_id = selectedGroup?.value;
      data.logo = logo;
      data.client_email = clientEmail;
      data.parent_project_id = parent_project_id;
      data.invite_entire_team = inviteEntireTeam;
      data.taskboard_id = selectedTaskboardTemplate;
      if((buyer != null) && (seller != null) ){
        data.sender_organization_id = document.getElementById("sender_organization_id").value 
        data.seller_organization_id = document.getElementById("seller_organization_id").value
      }

      const response = await axios.post(`/api/o/${organization_id || currentUser.primary_organization.token}/projects`, data)

      console.log(response);
      history.push(`/o/${response.data.organization.token}/projects/${response.data.project.token}/tasks`)
      await fetchProjectsAsync();
      onCloseModal();
      setDisabled(false);

    } catch (error) {
      console.log(error)
      notice("An error occured")
    }
  };

  const handleGroupSelect = (selectedOption) => {
    setSelectedGroup(selectedOption);
  };

  const groupOptions = groups.map(group => ({
    value: group.token,
    label: group.title
  }));

  const taskboardTemplateOptions = taskboardTemplates.map(taskboardTemplate => ({
    value: taskboardTemplate.token,
    label: taskboardTemplate.title
  }));


  return(
    loaded ?(
      <AnimatePresence>
        <React.Fragment>
          <h4 className="modal-title color-black-always mb-10">
            {parent_project_id === null ? "Add client" : "Add nested board"}
          </h4>

          <div className="row no-margin">
            {parent_project_id === null ?(
              <Filestack project={null} setImage={setLogo} image={logo || "https://g-epdw4cnc0di.vusercontent.net/placeholder.svg"}/>
            ):(
              <div className="field">
                {/* <div className="alert-warning font-12"><i className="fas fa-info-circle mr-5"></i>Your teammates in the parent project board will have access to this board.</div> */}
              </div>
            )}

            <form id="new_project" onSubmit={handleSubmit}>
              <div className="field">
                <label className="color-black-always">Title</label>
                <input onChange={(e) => setTitle(e.target.value)} name="title" className="form-control mb-10" placeholder="Client or company name" required autoFocus />
              </div>

              {parent_project_id != null && 
                <div className="field display-flex align-items-center gap-10">
                  <Toggle checked={inviteEntireTeam} onChange={(e) => setInviteEntireTeam(e.target.checked)} className="scale-75"/>
                  <p className="no-margin opacity-7">Add everyone to this board</p>
                </div>
              }

              <a className={`btn btn-small background-hover color-1 ml-15-negative mb-25`} onClick={() => setAdvanced(!advanced)}><i className={advanced ? "fas fa-cogs mr-5" : "fas fa-cog mr-5"}></i>Advanced options</a>

                {advanced && 
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.1 }}
                  >
                    <div className="field">
                      <label>Taskboard template</label>
                      <Select
                        options={taskboardTemplateOptions}
                        onChange={(e) => setSelectedTaskboardTemplate(e.value)}
                        className="min-width-350"
                        placeholder="Select..."
                        classNamePrefix="react-select"
                      />
                    </div>

                    {parent_project_id === null &&(
                      <>
                        <div className="field">
                          <label className="color-black-always">Invite client</label>
                          <input onChange={(e) => setClientEmail(e.target.value)} type="email" className="form-control mb-10" placeholder="Email of your client (optional)" />
                        </div>

                        {groups.length > 0 && 
                          <div className="field">
                            <label>Group</label>
                            <Select
                              options={groupOptions}
                              onChange={handleGroupSelect}
                              className="min-width-350"
                              placeholder="Select..."
                              classNamePrefix="react-select"
                            />
                          </div>
                        }
                      </> 
                    )}
                  </motion.div>
                }
              

              <div className="field text-right">
                <button className="btn btn-primary" type="submit" disabled={disabled || title === ""}>{t("Submit")}</button>
              </div>
            </form>
          </div>
        </React.Fragment>
      </AnimatePresence>
    ):(
      <div className="flex-center-no-width large-padding"><i className="fas fa-spinner fa-spin"></i></div>
    )
  )
}

export default New